import React from 'react'
import Layout from '../components/layout/layout'
import SEO from '../components/seo/seo'
import { graphql } from 'gatsby'
import { RichText } from 'prismic-reactjs'
import Link from 'gatsby-plugin-transition-link'
import { linkResolver } from '../utils/linkResolver'
import htmlSerializer from '../utils/htmlSerializer'

export const query = graphql`
  {
    prismic {
      allPrivacycookiepolicys {
        edges {
          node {
            content
            metatitle
            metadescription
            title
          }
        }
      }
    }
  }
`

export default ({ data }) => {
  const doc = data.prismic.allPrivacycookiepolicys.edges.slice(0, 1).pop()
  if (!doc) return null
  const title = RichText.asText(doc.node.title)
  const metaTitle = doc.node.metatitle ? RichText.asText(doc.node.metatitle) : title
  const metaDescription = RichText.asText(doc.node.metadescription)
  return (
    <Layout headerClass="c-navbar--article" footerClass="c-footer--article">
      <SEO
        title={metaTitle}
        description={metaDescription}
        pathname="/privacy-cookie-policy"
      />
      <div className="c-content">
        <div className="container-fluid">
          <div className="row">
            <div className="c-content__container">
              <h1 className="c-content__title">{title}</h1>
              <div className="c-content__text cms-content">
                {RichText.render(doc.node.content, linkResolver, htmlSerializer)}
              </div>
            </div>
          </div>
        </div>
        <div className="c-content__close">
          <Link to="/" className="c-content__close-link c-btn">
            <svg fill="none" viewBox="0 0 30 31">
              <path stroke="#fff" strokeWidth="2" d="M.9914 1.2929l28.2843 28.2843M28.9914 1.7071L.7071 29.9914"/>
            </svg>
          </Link>
        </div>
      </div>
    </Layout>
  )
}
